
import React from "react"

const Asylum = props=>{
    return(
        <>
            <h2>Asylum, WR, and CAT</h2>
            <p style={{marginBottom:'2rem'}}>Asylum, Withholding of Removal (“WR”), and Convention Against Torture (“CAT”). Commonly known as asylum. These are three distinct forms of relief available to individuals physically present in the United States and fear returning to their home country. Generally, this
                individual must apply for asylum within one year of last entering the United States. However, there are multiple exceptions to this rule. This individual was harmed or fears the harm will occur upon returning based on their race, religion, nationality, ethnicity, political opinion, or
                membership in something called a particular social group. Lastly, the government must have either been unable or unwilling to protect the individual. Here are just some of the type of cases we have handled successfully for clients from all over the globe:
            </p>
            <ul>
                <li>Family Members of Politically Active Individuals</li>
                <li>Former Law Enforcement</li>
                <li>Honor Killings</li>
                <li>Inter-Caste, Inter-Religious, Inter-Cultural Marriages</li>
                <li>Loss of Artistic Freedom of Expression</li>
                <li>Members of Ethnic and Tribal Groups</li>
                <li>Members of the LGBTQ+ Community and Supporters</li>
                <li>Political Activists</li>
                <li>Religious Conversion</li>
                <li>Survivors of Domestic Violence</li>
                <li>Survivors of Gang Violence</li>
                <li>Family Members of Survivors of Domestic Violence and/or Gang Violence</li>
                <li>Survivors of Female Genital Mutilation</li>
                <li>Whistleblowers</li>
                <li>Women That Resisted Various Forms of Societal and Cultural Oppression</li>
            </ul>
            <p>If you are afraid to return to your home country, even after years of remaining in the United States, call us today at (718)-715-1667 for your expert case consultation.</p>
        </>
    )
}
const Deportation = props=>{
    return(
        <>
            <h2>Deportation & Removal Defense (Deportation)</h2>
            <p>An individual facing deportation must appear in front of the Executive Office of Immigration Review (Immigration Court) to contest the charges made against them by
            the Office of Principal Legal Advisor (government prosecutor’s office). An individual facing deportation may apply for multiple times of relief that can halt the process and,
             in certain cases, even result in the immigration judge granting a green card. Furthermore, there is a wide array of immigration court specific rules, deadlines, case law, 
             and other matters that will impact your chance of success.</p>
            <p>If you have an immigration court case, call us today at (718)-715-1667 for your expert case consultation.</p>
        </>
    )
}
const Appeals = props=>{
    return(
        <>
            <h2>Appeals (Board of Immigration Appeals)</h2>
            <p>Commonly known as an appeal. Once an immigration judge makes a decision in your case, you have thirty days to file an appeal with a higher administrative agency called the Board of Immigration Appeals. Here, an individual wants to challenge the factual and/or legal conclusions of the immigration judge, trying to prove that the judge should not have denied their case. It takes a lot of investigation work to gather all of the immigration records, fully understand why the case was denied, and find the best arguments to make.</p>
            <p>If you lost your initial case with the immigration court or the government appealed your case, do not delay, call us today at (718)-715-1667 for your expert case consultation. </p>
        </>
    )
}
const Motions = props=>{
    return(
        <>
            <h2>Motions to Reopen</h2>
            <p>For individuals who were already deported from the United States, never left, and now want to reopen their case to try to resolve their immigration case. Over the years, this individual may have married, had children, continues to care or financially support family members with medical or mental health issues, heard about new problems in their home country that affects them, or realized that something just did not feel right about their old case. At times, this individual did not even know they had been deported by the court. There are multiple ways we can help you reopen your case, but some of these options are time-sensitive.</p>
            <p>If you think you were ordered removed, do not delay, call us today at (718)-715-1667 for your expert case consultation to see if you qualify.</p>
            {/* <ul>
                <li>Changed in Country Conditions</li>
                <li>Ineffective Prior Representation</li>
                <li>Change in Law</li>
                <li>Lack of Notice</li>
                <li>Eligibility for new forms of relief</li>
            </ul> */}
        </>
    )
}
const Marriage = props=>{
    return(
        <>
            <h2>Adjustment of Status</h2>
            <p>Commonly known as acquiring a green card. For those that individuals that wish to acquire a green card based on their family connections or attainment of some kind of special designation by the United States government. Depending on the reasons behind your green card application, you will have to prove various items to the satisfaction of an immigration officer. This may include but is not limited to: entry into the country, genuine relationship to relatives with immigration status, whether you will use federal public benefits in the future, whether something about your history will prevent you from being worthy of a green card.</p>
            <p>There are various waivers, exceptions, and important pitfalls in the process, call us today at (718)-715-1667 for your expert case consultation.</p>
        </>
    )
}
const Removal = props=>{
    return(
        <>
            <h2>Removal of Conditions</h2>
            <p>Commonly known as acquiring a ten-year green card. For those individuals that acquire their green card through marriage and only receive a temporary two-year green card. Prior to the expiration of the individual’s temporary card, they must file to remove the limitations on their green card. Though some couples may still be happily married, others may have faced serious challenges resulting in separation or divorce. You may think you no longer qualify or you fear that you will lose your green card. Do not let fear consume you.</p>
            <p>There are various waivers and exceptions that may apply, call us today at (718)-715-1667  for your expert case consultation. </p>
        </>
    )
}
const Domestic = props=>{
    return(
        <>
            <h2>VAWA Relief</h2>
            <p>Violence Against Women Act (“VAWA”) is a federal law that provides a wide range of immigration benefits and exceptions to many legal requirements for individuals who suffered some form of battery or extreme cruelty. A common misconception is that this relief is only available to women. This is not so, men may also qualify. Another common misconception is that the abuse must have been physical. This is also not the case, as the abuse could take the form of psychological harm, acts of economic control, and emotional abuse, just to name a few.</p>
            <p>VAWA is available to an abused spouse or child of a U.S citizen or green card holder. It is also available to an abused parent of an adult U.S citizen child. These non-citizens and their derivatives (i.e. certain qualifying children or parents of the abused individual) can then self-petition without the abusive individual ever being aware of the application.  Importantly, among other benefits, VAWA approval leads to an individual receiving legal permanent residency (a green card), affect multiple types of immigration applications, impact your chances of reopening an old immigration case, and affect the kind of relief you can apply for in immigration court.</p>
            <p>If you feel like VAWA may apply to your situation, call us today at (718)-715-1667 for your expert case consultation.</p>
            <h3 style={{color:'#333', display:'inline-block', margin:'0'}}>*VAWA Applicant Safety: </h3><p> Please note that the law has various safety provisions built in to the legislation, which were designed for an individual to <i>file without the abuser ever being aware of your application</i>. Furthermore, Feodoroff Law is fully committed to maintaining the highest level of client confidentiality at all stages of the process. </p>
        </>
    )
}
const Cooperating = props=>{
    return(
        <>
            <h2>Cooperating With Law Enforcement</h2>
            <p>Commonly known as a U Visa. This type of application is for individuals who have been the victims of certain crimes, suffered a degree of abuse, and are helpful to law enforcement efforts to investigate or prosecute the criminal activity. A U Visa can lead to an individual receiving legal permanent residency (a green card) and provide benefits for certain family members. Importantly, a U Visa approval will also positively impact an individual’s ability to reopen an old immigration deportation order, other forms of deportation called “expedited removal”, and positively impact any ongoing immigration court proceedings.</p>
            <p>If you feel like a U Visa may apply to your situation, call us today at (718)-715-1667 for your expert case consultation.</p>
            <h3 style={{color:'#333', display:'inline-block', margin:'0'}}>*U Visa Applicant Safety: </h3><p> Please note that the law has various safety provisions built in to the legislation, which were designed for an individual to <i>file without the perpetrator of the crime from ever being aware of your application</i>. Furthermore, Feodoroff Law is fully committed to maintaining the highest level of client confidentiality at all stages of the process.</p>
        </>
    )
}
const Victims = props =>{
    return(
        <>
            <h2>Victims of Human Trafficking</h2>
            <p>Commonly known as a T Visa. An individual already in the United States due to the force, fraud, or coercion of someone else may be eligible for a T Visa. Applicants must also demonstrate that they were a victim of severe human trafficking, which generally includes sex and labor trafficking. A T visa may also be available to qualifying family members, which may include spouses, children, parents, and even siblings. A T Visa can lead to the individual receiving legal permanent residency (a green card). Importantly, a T Visa approval will have a significant positive impact on any old deportation order or ongoing immigration court case.</p>
            <p>If you feel like a T Visa may apply to your situation, call us today at (718)-715-1667 for your expert case consultation.</p>
            <h3 style={{color:'#333', display:'inline-block', margin:'0'}}>*T Visa Applicant Safety: </h3><p>Please note that the law has various safety provisions built in to the legislation, which were designed for an individual to <i>file without the abuser ever being aware of your application</i>. Furthermore, Feodoroff Law is fully committed to maintaining the highest level of client confidentiality at all stages of the process.</p>
        </>
    )
}

const ServicesArr = [
    Asylum,
    Deportation,
    Appeals,
    Motions,
    Marriage,
    Removal,
    Domestic,
    Cooperating,
    Victims
]

const ServicesDetail = props =>{
    var temp = 1
    if(props.selected != 0){
        temp = props.selected
    }
    return(
       
        ServicesArr[temp-1]()
        
    )
}

// const ServicesDetail = [
export{
    ServicesDetail, 
}


// export default ServicesDetail