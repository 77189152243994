import React, {useState} from "react"
import styled from "@emotion/styled"
// import { css } from "@emotion/core"
// import Img from "gatsby-image"
import { Link } from "gatsby"
// import { useImagesServices } from "../hooks/use-images-services"
import { Waypoint } from 'react-waypoint';
import { TextContainer } from './common'




const Bg = styled.div`
   background-color: white;
   width: 100%;
   color: #888;
   overflow: hidden;
   min-height: 310px;
   position: relative;

   h1{
    margin-top: 0;
    color: #333;
    text-align: center;
    }

   @media (max-width: 600px) {
    min-height: 270px;
     span{
        font-size: 1.2rem; 
     }  
     p{
        font-size: 1.2rem; 
     }

   }  
`

const FormContainer = styled.div`
   
    width:  100%;
    color: #214c9b;
    margin: auto;

    input{
        @media (max-width: 600px) {
            font-size: 1.2rem;
            
        }
    }  
    
    animation-duration: 0.7s;

    @keyframes animationNewsletter {
        0%   {margin-top: 30%;}
        100% {margin-top: 0%;}
    }
`
const TextInput = styled.input`
    display: block;
    margin: auto; 
    border: 1px solid black; 
    color: black;
    outline: 0;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    padding: 0.6rem 1.2rem;
    width: 500px;
    @media (max-width: 600px) {
        width: 320px;
    }
`

const SubmitInput = styled.input`
    margin: auto;
    background-color: transparent;
    color: #666;
    padding: 3px  30px 3px 30px; 
    border: 2px solid #0099df;
    cursor: pointer;
    width: max-content;
    display: block;
    font-size: 1.3rem;

    transition: 0.3s;
    width: 18rem;

    :hover{
        border-radius: 25px;
    }
    @media (max-width: 600px) {
        border-radius: 5px;
    }
`

const BorderContainer = styled.div`
    height: 300px;
    width: 98%;
    margin: auto;
    border: 2px solid #214C9B;
    
    @media (max-width: 600px) {
        height: 260px;
    }
`

// let Images =  {}
// let animationName = '' 
var useremail = ""


const NewsletterSec = (props) =>{
    const [rendering, setRendering] = useState(false);

    // Images = useImagesServices()

    const _handleEnter = () =>{
        // animationName = 'animationNewsletter'
        setRendering(true)
    }

    const handleUpdate = (event) => {
        if(event.target.name == "email"){
            useremail = event.target.value
        }
    }
    const handleSubmit = async event => {
        await event.preventDefault()
        if(useremail != ''){    
            await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/email_subscribe`,
            {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'email': useremail})})
            .then(res => res.json())
            .then(json => {
                // console.log("this is my response: ",json)
                // alert('Congratulations you are now subscribed!')
                alert(json.message)
            });
        }
      }

    return(
        
        <Bg>
            <BorderContainer>
                <TextContainer style={{width:'100%'}}>
                    <h1>Newsletter</h1>
                    {/* <p style={{width:'100%', textAlign:'center', marginBottom:'2rem'}}>Join our newsletter and stay informed of the latest changes on immigration law.</p> */}
                    <p style={{margin:'auto', width:'100%', textAlign:'center'}}>Join our free monthly newsletter!</p>
                    <p style={{margin:'auto', width:'100%', textAlign:'center', marginBottom:'2rem'}}> Stay informed of the latest changes in immigration law and find out more about the exciting work our firm does. </p>
                        
                    <Waypoint style={{position:'relative'}} onEnter={_handleEnter}>
                        <FormContainer> 
                            <form style={{width:'100%', color:'#FDE3E3'}} onSubmit={event => {
                                    event.target.reset();
                                    (async () => { 
                                        await handleSubmit(event)
                                    }) ();
                                    
                                    }}>
                            <TextInput type="text" name="email" placeholder='your email' onChange={handleUpdate}/>
                            <SubmitInput type="submit" value="Join"/>
                            </form>
                            <Link to='/newsletter' style={{fontSize:'1.2rem', width:'max-content', display:'block', cursor: 'pointer', color:'#214c9b', margin: 'auto'}} activeStyle={{ color:'#34e3fe'}}><span>Read All News</span></Link>
                        </FormContainer>
                    </Waypoint>
                </TextContainer>
                <div style={{position:'absolute', top:'90%'}} ></div>
            </BorderContainer>
        </Bg>   
    
    )
}

export default NewsletterSec
