import React, {useState} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
// import { Link } from "gatsby"
import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
import { Waypoint } from 'react-waypoint';
import { TextContainer } from './common'




const Bg = styled.div`

   width: 100%;
   color: #888;
   z-index: -1;
   overflow: hidden;
   position: relative;


   h1{
       color: #333;
       text-align: center;
       margin-bottom: 10px;
       margin-top: 8rem;
   }
   p{
        margin-top: 0;
        margin-bottom: 3.1vw;

    }   
`

const CircleDiv = styled.div`
    border-radius: 50%; 
    width: 22vw;
    height: 22vw;
    background-color: #0099df;
    color: white;
    float: left;
    margin-right: 8vw;
    position: relative;
    margin-top: 0%;

    h1{
        margin: auto;
        color: white;
        font-size: 2vw;
        margin-bottom: 5px;
        margin-top: 0;
        max-width: 18vw;
        text-align: center;

        @media (max-width: 600px) {
            font-size: 2rem;
            max-width: 32vw;
        }
        @media (max-width: 480px) {
            font-size: 1.6rem;
        }
        @media (max-width: 400px) {
            font-size: 1.4rem;
        }
    }
    p{
        font-size: 1.4vw;
        line-height: 160%;
        max-width: 40%;
        text-align: center;
        margin-left: 30%;

        @media (max-width: 600px) {
            font-size: 1.3rem;
            max-width: 32vw;
            margin-left: auto;
        }
        @media (max-width: 450px) {
            font-size: 1rem;
        }
    }

    @media (max-width: 600px) {
        float: none;
        margin: auto;
        width: 40vw;
        height: 40vw;
        margin-bottom: 4vw;

    }

    animation-timing-function: ease-out;
    animation-duration: 1.5s;

    @keyframes movement1b {
        0%   {margin-top: 50%;}
        33%   {margin-top: 0%;}
        66%   {margin-top: 0%;}
        100% {margin-top: 0%;}
    }

    @keyframes movement2b {
        0%   {margin-top: 50%;}
        33%   {margin-top: 50%;}
        66%   {margin-top: 0%;}
        100% {margin-top: 0%;}
    }

    @keyframes movement3b {
        0%   {margin-top: 50%;}
        33%   {margin-top: 50%;}
        66%   {margin-top: 50%;}
        100% {margin-top: 0%;}
    }

`

const Quote = styled.div`
    p{
        color: #333;
        font-size: 3vw;
        max-width: 95%;
        margin: auto;
        @media (max-width: 600px) {
            font-size: 5vw;   
            max-width: 90%;
            margin-left: 5%;                         
        }
    }
`
// background-color: rgba(255, 255, 255,0.6);
// background: linear-gradient(0deg, rgba(255,255,255, 0.3) 0%, rgba(255,255,255, 0.7) 50%);

const FinalQuote = styled.div`
    color: white;
    padding: 3rem;
    border: 4px solid white;
    width: 96%;
    margin-left: 2%;
    margin-bottom: 4rem;
    background-color: rgba(0, 0, 0, 0.3);

    h2{
        width: 90%;
        max-width: 1000px;
        margin: auto;
        color: white;
        font-weight: 600;
    }
    h3{
        width: 90%;
        max-width: 1000px;
        margin: auto;
        margin-top: 2rem;
        color: white;
    }

` 

let Images =  {}
let animationNames = ['','',''] 





const PhilosophySec = (props) =>{
    const [rendering, setRendering] = useState(false);

    Images = useImagesPhilosophy()

    const _handleEnter = () =>{
        // animationNames = ['movement1b', 'movement2b', 'movement3b']
        // setRendering(true)
    }

    return(
        <Bg id='s_philosophy_wrapper'>
            {/* <div id='s_philosophy_content_wrapper' style={{position:'absolute', top:'0', width:'100%', zIndex:'1'}}> */}
            <div id='s_philosophy_content_wrapper' style={{position:'relative', zIndex:'1'}}>
                {/* <div style={{width:'100%', margin:'auto'}}> */}
                    <h1 style={{textAlign:'center'}}>Philosophy</h1>
                    <p css={css`
                        @media (max-width: 600px) {
                            display: none;                                     
                        }
                        `} style={{margin:'auto', letterSpacing:'1.5px', marginBottom:'2rem', color: '#666', textAlign:'center', lineHeight:'160%', maxWidth:'700px', width:'90%'}}>Our goal is to help immigrants remain in the United States with their families, overcome their fears, and gain legal status to live a more fulfilling life. Our Firm's experience, diversity, and commitment to the field of immigration law make us uniquely qualified to help people from all around the world achieve their immigration goals.</p>
                    <Quote id='s_philosophy_quote_div'>
                        <p style={{textAlign:'center', marginBottom: '0'}}>
                        "IT ALWAYS SEEMS IMPOSSIBLE UNTIL IT IS DONE"
                        </p>
                    </Quote>
                    <div id='s_philosophy_wrapper_author_div'css={css`
                        p{
                            font-size: 1.2vw;
                            text-align: right;
                            width: 85%;
                            @media (max-width: 600px) {
                                font-size: 2.2vw;  
                                text-align: center;
                                width: 100%;

                            }
                        }
                        `}>
                            <p style={{color:'#666', lineHeight:'160%'}}>NELSON MANDELA</p>
                        </div>
                    <Waypoint id='s_philosophy_wrapper_animation_trigger' onEnter={_handleEnter}>
                        <div id='s_philosophy_wrapper_circles_container' css={css`
                            margin-left: 9%;
                            overflow: auto;
                            margin-bottom: 4.6vw;
                            @media (max-width: 600px) {
                                margin-left: 0%;                                                            
                            }
                            `}style={{width: "100%",  marginTop:'2vw'}}>
                            <CircleDiv style={{animationName:animationNames[0]}}>
                                <TextContainer style={{top:'57%'}}>
                                    <h1>CLIENT ORIENTED</h1>
                                    <p>Provide Maximum Value For Our Clients/ Technologically Savvy/Value Communication</p>
                                </TextContainer>
                            </CircleDiv>
                            <CircleDiv style={{animationName:animationNames[1]}}>
                                <TextContainer style={{top:'60%'}}>
                                    <h1>OBSESSED WITH QUALITY</h1>
                                    <p style={{maxWidth:'40%', textAlign:'center', marginLeft:'30%'}}>Consistency/ Meticulous/ Attention To Detail</p>
                                </TextContainer>
                            </CircleDiv>
                            <CircleDiv style={{animationName:animationNames[2]}}>
                                <TextContainer style={{top:'57%'}}>
                                    <h1>RESULTS DRIVEN</h1>
                                    <p style={{maxWidth:'40%', textAlign:'center', marginLeft:'30%'}}>Creative Legal Solutions/ Zealous Advocacy/Long-Term Vision</p>
                                </TextContainer>
                            </CircleDiv>
                        </div>
                    </Waypoint>   
                    <FinalQuote>
                        {/* <TextContainer style={{top:'50%'}}> */}
                        {/* <h3>A message From The Firm’s Founding Partner, Nikolay Feodoroff, Esq.:</h3> */}
                        <h2><i>“Ultimately, this firm was started to help people like you- culturally diverse, unwilling to return to your home country, hardworking, family-oriented, resilient, and looking for a better future. If you dream of finding a way to stay in the United States legally, then allow us to do everything in our power to help you.”</i></h2>
                        <h3>Nikolay Feodoroff, Firm’s Founding Partner. </h3>
                        {/* </TextContainer> */}
                    </FinalQuote>
                {/* </div> */}
            </div>
            <div style={{position:'absolute', bottom: '0', width: '100%'}}>            
                <div id='s_philosophy_bg_shade' css={css`
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    position: absolute;
                    background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 98%);
                    @media (max-width: 900px) {
                        background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 98%);                    
                    }                
                    @media (max-width: 600px) {
                        background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255,1) 99%);
                        height: 173vw;
                    }
                    `} style={{zIndex:'-1', position:'absolute'}}/>

                <Img id='s_philosophy_bg_img' css={css`
                    position: absolute;
                    z-index: -2;
                    width: 100%;
                    top: 15%;
                    @media (max-width: 900px) {
                        top: 25%;                
                    }
                    @media (max-width: 600px) {
                        width: 180%;
                        top: 50%;
                        left: -27%;
                    }
                    `}
                    
                    fluid={Images['side_view'].childImageSharp.fluid}
                />  
            </div>          
        </Bg>
       
    )
}
// style={{zIndex:'-2', position:'absolute'}} 
export default PhilosophySec
