import React, { useState, useEffect } from "react"
// import Header from '../components/header_bg'
import Header2 from '../components/header_new'
import ServicesSec from '../components/services_section'
import PhilosophySec from '../components/philosophy_section'
// import PerdiemSec from '../components/per_diem_section'
import NewsletterSec from '../components/newsletter_section'
import ReviewsSec from '../components/reviews_section'
import ContactSec from '../components/contact_section'
import Footer from '../components/footer'
import BtnUp from '../components/btn_up'
import NavBar from '../components/nav-bar'
import NlSign from '../components/nl_sign'
import ConsulSign from '../components/consul_sign'

import { getShowNL, setShowNL } from "../cookies/nl_sign_cookie"
import { getMenuItem, setMenuItem } from "../cookies/submenu_cookie"
import scrollTo from 'gatsby-plugin-smoothscroll'


import { Body } from '../components/common'
import { navigate } from "gatsby";

// import { Helmet } from "react-helmet"




let scroll_section = ''
let scroll_before = ''


export default () =>{ 
    var item1 = 1
    // var item2 = 0
    var show = true

    if(!getShowNL || !getShowNL()['show']){
        setShowNL({'show': true})
    }else{
        show = getShowNL()['show']
    }

    if(!getMenuItem){
        item1 = 1
        // item2 = 0
        setMenuItem({'item': 1})
    }else{
        item1 = getMenuItem()['item']
        // item2 = getMenuItem()['item2']
    }

    // if(item1 == undefined || item2 == undefined){
    if(item1 == undefined){
        item1 = 1
        // item2 = 0
    }
    item1 = 0

    // const [service, setService] = useState(item1);
    // const [perDiem, setPerDiem] = useState(item2);
    const [services, setServices] = useState({'item1':item1});
    const [consultation, setConsultation] = useState(false);
    
    const changeService = (evt) =>{
        console.log('option: ', evt.target.id)
        var menu_temp = getMenuItem()

        if(evt.target.id == 'true'){
        // console.log('scroll to is')

            scrollTo('#is')
            // setMenuItem({'item': evt.target.name})
            menu_temp['item'] = evt.target.name
            // menu_temp['item2'] = 0
            setMenuItem(menu_temp)
            setServices({'item1':evt.target.name})
        }else{
            navigate('/per_diem')
        }
        // else{
        // // console.log('scroll to perdiem')

        //     scrollTo('#per_diem')
        //     menu_temp['item2'] = parseInt(evt.target.name) - 1
        //     menu_temp['item1'] = 0
        //     setMenuItem(menu_temp)
        //     // setPerDiem(parseInt(evt.target.name) - 1)
        //     // setService(0)
        //     setServices({'item1':0, 'item2':evt.target.name-1})


        // }
    }

    // const changePerdiem = (item) =>{
    //     var menu_temp = getMenuItem()
    //     menu_temp['item2'] = item
    //     menu_temp['item1'] = 0
    //     setMenuItem(menu_temp)
    //     // setPerDiem(item)
    //     // setService(0)
    //     setServices({'item1':0, 'item2':item})
    // }  
    const changeIS = (evt) =>{
        // console.log(evt)
        var menu_temp = getMenuItem()
        
        if(menu_temp['item'] == evt.target.name){
            menu_temp['item'] = 0
            // menu_temp['item2'] = 0
            setMenuItem(menu_temp)
            setServices({'item1':0})
        }else{
            menu_temp['item'] = evt.target.name
            // menu_temp['item2'] = 0 
            setMenuItem(menu_temp)
            // setService(evt.target.name)
        setServices({'item1':evt.target.name})

        }
    }  

    const openConsulSign = (evt) =>{
        // setServices(0)
        setConsultation(true)
    }

    useEffect(() => {
        if(scroll_section != '' && scroll_section != scroll_before){
            // console.log('scrolling to: ', scroll_section)
            scrollTo(scroll_section)
            scroll_before = scroll_section
        }
    })
    
    // console.log(getMenuItem()['item'])

    return(
        <Body>
            <NavBar pickService={changeService} iService={services['item1']} perDiem={0} isSticky={false} consulOpen={openConsulSign}/>
            <NavBar pickService={changeService} iService={services['item1']} perDiem={0} isSticky={true} consulOpen={openConsulSign}/>
            <NlSign delay={120} open={false}/>
            <div style={{height:'50px'}}></div>
            <ConsulSign open={consultation} onClose={()=>setConsultation(false)}/>
            <Header2/>
            <PhilosophySec/>
            <ServicesSec pickService={changeIS} item={services['item1']} scrollToSection={(section)=>scroll_section=section}/>
            {/* <PerdiemSec pickService={changePerdiem} item={services['item2']}/> */}
            <NewsletterSec/>
            <ReviewsSec/>
            <ContactSec/>
            <Footer/>
            <BtnUp/>
        </Body>
    )
}

