import React from "react"
import styled from "@emotion/styled"
// import { css } from "@emotion/core"
import Img from "gatsby-image"
import { useImagesHeader } from "../hooks/use-images-header"
import { Link } from "gatsby"
import { Actionbutton, ActionbuttonInverted } from './common'




// @media (max-width: 490px) {
//     height: 74vw;
// }
// @media (max-width: 600px) {
//     background-color: #0099df;
//     height: 54vw;
// }
const Bg = styled.div`
   background-color: transparent;
   width: 100%;
   overflow: hidden;
   position: relative;
`
const ContLeft = styled.div`
    width: 50%;
    height: 100%;
    float: left;
    background-color: #214c9b;
    z-index: -1;
    overflow: hidden;
    @media (max-width: 600px) {
        width: 0%;
    }
`
const ContRight = styled.div`
    width: 50%;
    height: 100%;
    float: left;
    background-color: #0099df;
    z-index: -1;

    @media (max-width: 600px) {
        width: 100%;
    }
`

const AnimatedPic = styled.div`
    animation-name: animationPic;
    animation-duration: 1.5s;

    @keyframes animationPic {
        0%   {opacity: 0;}
        100% {opacity: 1;}
    }
`

const WhiteSquare = styled.div`
    border: 4px solid white;
    z-index: 2;
    position: absolute;
    height: 28vw;
    width:  50%;
    top: 8vw;
    right: 5%;
    padding-top: 4.6vw;
    color: white;
    display: none;
    min-height: 280px;

    h1{
        delete margin-bottom, font-size;
        background-color: transparent;
        display: inline-block;
        margin-left: 9vw;
        margin-top:  0vw;
        margin-bottom:  0vw;
        color: white;

    }
    h2{
        delete style.margin;
        delete font-size;
        display: inline-block;
        margin-left: 9vw;
        margin-top:  0vw;
    }

    p{
        margin-left: 9vw;
        max-width: 75%;
        margin-top: 0vw;
        margin-bottom: 1.5vw;
        letter-spacing: 1.5px;

    }
    span{
        font-size: 1.3rem;      
    }

    animation-duration: 2s;

    @keyframes animationSquare {
        0%   {right: -80%;}
        100% {right: 5%;}
    }

    @media (min-width: 601px) {
        animation-name: animationSquare;
    }

    @media (max-width: 600px) {
        display: none;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 80%;
        top: 10%;
        height: 40vw;
    }
    

    @media (max-width: 490px) {
        height: 60vw;
    }
`
const TextContainer = styled.div`
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 100%;
    width: max-content;

    p{
        text-align: left;
        margin-bottom: 2rem;
        
    }

    @media (max-width: 500px) {
        left: 47%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        p{
            margin-top: 1rem;
        }
    }
`
const ImgContainer = styled.div`
    width: 100%;
    z-index: -1;
    @media (max-width: 600px) {
        display: block;
    }
`

const WhiteSquareBig = styled.div`
    border: 4px solid white;
    z-index: 2;
    position: absolute;
    height: 92%;
    width:  96%;
    top: 6%;
    right: 2%;
    color: white;

    h1{
        display: inline-block;
    }
    h2{
        display: inline-block;
    }
    p{
        letter-spacing: 1.5px;
    }

    @media (max-width: 600px) {
        display: block;
    }
`





const Header2 = (props) =>{
    let Images = useImagesHeader()

    return(
        <Bg id='s_who_we_are_wrapper'>
           <ImgContainer>
                <Img style={{width: '100%', heigth: '100%'}} id='header_img' fluid={Images['panoramic'].childImageSharp.fluid}/>   
            </ImgContainer>
            <WhiteSquareBig>
                <Img style={{width: '48%', marginTop:'2vw', marginLeft:'26%'}} id='header_img' fluid={Images['logo_full'].childImageSharp.fluid}/>   
                {/* <div style={{position: 'absolute', width: '50%', top:'9vw', right:'4rem'}}>
                    <h1 style={{marginTop:'0'}}>Feodoroff Law PLLC</h1>
                    <br></br>
                    <h2 style={{marginBottom: '0.2vw'}}>Immigration Firm</h2>
                    <p style={{marginBottom: '1rem', width: '90%', maxWidth:'500px'}}>Our interest comes from giving everyone, no matter their race, religion, education level, background, or origins, access to due process. </p>
                    <Link id='s_who_we_are_button' style={{ boxShadow:'none', textDecoration:'none'}} to='/who_we_are'><ActionbuttonInverted to='/who_we_are' style={{marginRight:'1rem' , color:'white', float: 'left', padding: '0.5vw 2vw'}}><span>More</span></ActionbuttonInverted></Link>
                </div> */}
            </WhiteSquareBig>
            <WhiteSquare id='s_who_we_are_sign'>
                    <TextContainer id='s_who_we_are_text_container'>
                        <h1 style={{textAlign:'left'}}>Feodoroff Law PLLC</h1>
                        <br></br>
                        <h2 style={{marginBottom: '0.2vw'}}>Immigration Firm</h2>
                        {/* <p>My goal is to make clients understand that I understand their struggle, even if I may not look like them, but my education, personal story, and expertise makes me uniquely qualified to guide them on the path to achieving their immigration goals.</p> */}
                        <p style={{margin:'0.5rem auto 1rem '}}>Our interest comes from giving everyone, no matter their race, religion, education level, background, or origins, access to due process. </p> 
                        <Link id='s_who_we_are_button' style={{ boxShadow:'none', textDecoration:'none'}} to='/who_we_are'><ActionbuttonInverted to='/who_we_are' style={{ color:'white', marginLeft:'9vw', padding: '0.5vw 2vw'}}><span>More</span></ActionbuttonInverted></Link>
                    </TextContainer>
            </WhiteSquare>
        </Bg>   
    )
}

export default Header2
