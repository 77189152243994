import React, {useState} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { Waypoint } from 'react-waypoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import { SquareButton, TextContainer } from './common'




const Bg = styled.div`
   width: 100%;
   height: max-content;
   color: white;
   z-index: -2;
   overflow: hidden;
   @media (min-width: 602px) {
        background: linear-gradient(
            to bottom,
            #142f5e,
            #142f5e 40%,
            white 40%,
            white
        );
        background-size: 100% 100%;
    }
   

   h1{
       color: white;
       text-align: center;

   }
   
    @media (max-width: 700px) {
        min-height: 480px;
    }

    @media (max-width: 600px) {
        min-height: 360px;
        background-color: #eeeef0;
    }

`

const ReviewContainer = styled.div`
    z-index: 1;
    width: 30%;
    float: left;
    margin-top: 0%;
    margin-left: 2.5%;
    margin-bottom: 1rem;

    background-color: #eeeef0;
    animation-duration: 1s;
    padding: 3vw;
    color: #333;
    height: 300px;

    padding: 2.5rem 3.5rem; 
    min-height: 21rem;

    cursor: pointer;


    @keyframes movement1 {
        0%   {margin-top: 25%;}
        33%   {margin-top: 0%;}
        66%   {margin-top: 0%;}
        100% {margin-top: 0%;}
    }

    @keyframes movement2 {
        0%   {margin-top: 25%;}
        33%   {margin-top: 25%;}
        66%   {margin-top: 0%;}
        100% {margin-top: 0%;}
    }

    @keyframes movement3 {
        0%   {margin-top: 25%;}
        33%   {margin-top: 25%;}
        66%   {margin-top: 25%;}
        100% {margin-top: 0%;}
    }

    @media (max-width: 700px) {
        width: 90%;
        margin-left: 5%;
    }

`

const SmallDisplay = styled.div`
    display: none;
    margin-bottom: 2rem;
    position: relative;
    @media (max-width: 600px) {
        display: block;                                
    }
`

const ArrowBtn = styled.button`
    background-color: transparent;
    border: none;
    transition: 0.5s;
    padding: 0rem 1rem;
    cursor: pointer;
    top: 40%;
    z-index: 2;
    font-size: 1.8rem;
    
    :hover {
        background-color: rgba(0,0,0,0);
        color: #888;
    }
    :focus {
        outline: none;
        box-shadow: none;
    }
    @media (max-width: 900px) {
        top: 50%;
    }
    @media (max-width: 600px) {
        font-size: 4rem;
        color: #a0a0ab;
        top: 70px;
    }
`


let animationNames = ['','',''] 
let reviews_arr = ['"Nikolay is a very thorough and intelligent Immigration lawyer."', '"Mr. Feodoroff is the kind of lawyer you need when it comes to immigration work...."', '"I have recommended Nikolay Feodoroff to many people..."']
let reviews_detail_arr = [`"Not only he understands the ins and outs of Immigration law but also will prepare you for the best/worst scenarios. We've come across a few other Immigration Lawyers however it always felt like we were just another case for them. With Nikolay, the entire experience is very personal and he will make sure you're not just another case for him. He is surely one of the best Immigration Lawyer on the East Coast and will make sure that you as an Individual is being represented in the best way possible."`, `"Mr. Feodoroff is the kind of lawyer you need when it comes to immigration work. Not only he is smart and knowledgeable, but also he is very patient and hardworking. For years he managed my case and I can't say enough or thank him enough for the work he did. He worked on my case like his life depended on it and he was very patient and professional answering the thousands of questions I had."`, `"I have recommended Nikolay Feodoroff to many people. It's hard to find someone that knows what they are talking about and wants to help people. Thumbs up!"`]
let animationInfo = 'fading_mov'

const ReviewBox = props =>(
    <ReviewContainer style={{animationName:props.animationName}}>
        {/* <button onClick={()=>console.log('yolo')} style={{backgroundColor: 'pink', cursor: 'pointer', position:'absolute', height: '100%', width:'100%', zIndex:'3'}} /> */}
        <h1 css={css`
            display: none;
            @media (max-width: 600px) {
                display: block;                                
            }
            `}style={{zIndex:'1', textAlign:'center', marginTop:'0', color:'#333'}}>{props.name}</h1>
        <h3 css={css`
        display: block;
        @media (max-width: 600px) {
            display: none;                                
        }
        `}style={{zIndex:'1', textAlign:'center'}}>{props.name}</h3>
        <p style={{color:'#888', marginBottom:'0', marginTop:'0'}}>{props.detail}</p>
    </ReviewContainer>
) 

const MobReviewBox = props =>(
        <ReviewContainer style={{animationName:props.animationName, position:'relative', height:'260px'}}>
            <TextContainer style={{maxWidth:'85%', top:'40%'}}>
                <div>
                    <h1 style={{zIndex:'1', textAlign:'center', marginTop:'0', color:'#333'}}>{props.name}</h1>
                    <p style={{color:'#888', marginBottom:'0', marginTop:'0'}}>{props.detail}</p>
                </div>
            </TextContainer>
        </ReviewContainer>
)




const ReviewsSec = (props) =>{
    const [rendering, setRendering] = useState(false);
    const [slide, setSlide] = useState(0);


    const _handleEnter = () =>{
        animationNames = ['movement1', 'movement2', 'movement3']
        setRendering(true)
        // console.log("I just entered the viewport!")
        // alert("I just entered the viewport!")
    }

    const changeSlideL = () =>{
        if(animationInfo == 'fading_mov'){
            animationInfo = 'fading_mov2'
        }else{
            animationInfo = 'fading_mov'
        }

       if(slide == 0){
            setSlide(2)
       }else{
            setSlide(slide-1)
       }
        //console.log("The current slide is: ", this.state.Currentslide)
    }
    const changeSlideR = () =>{
        if(animationInfo == 'fading_mov'){
            animationInfo = 'fading_mov2'
        }else{
            animationInfo = 'fading_mov'
        }

        if(slide == 2){
            setSlide(0)
       }else{
            setSlide(slide+1)
       }
        //console.log("The current slide is: ", this.state.Currentslide)
    }

    return(
        <>
        
        <Bg>
            <div css={css`
                height: 0px;                                 
                @media (max-width: 600px) {
                    height: 30px;                              
                }
                `}/>
            <div style={{height:'30px'}}/>    

            <div id='reviews'/>

            <div css={css`
                        display: block;
                        @media (max-width: 600px) {
                            display: none;                                
                        }
                        `}>
                <h1>Reviews</h1>
                <p style={{textAlign:'center'}}>Here are some success stories from previous clients</p>
            </div>

            <Waypoint onEnter={_handleEnter}>
                <div>
                    <div css={css`
                        display: block;
                        @media (max-width: 600px) {
                            display: none;                                
                        }
                        `}style={{width:'100%', marginTop:'1%', overflow:'auto'}}>
                        {/* <ReviewBox image='icon2' name='"The best service experience..."' animationName={animationNames[0]}/> 
                        <ReviewBox image='icon2' name='"I found a really concerned and effective lawyer in this firm..."' animationName={animationNames[1]}/> 
                        <ReviewBox image='icon2' name='"My Lawyer explained to me all the fuzzy details of my case with great clarity..."' animationName={animationNames[2]}/>  */}
                        <ReviewBox image='icon2' name={reviews_arr[0]} detail={reviews_detail_arr[0]}/> 
                        <ReviewBox image='icon2' name={reviews_arr[1]} detail={reviews_detail_arr[1]}/> 
                        <ReviewBox image='icon2' name={reviews_arr[2]} detail={reviews_detail_arr[2]}/> 
                    </div> 
                    <SmallDisplay>
                        <MobReviewBox animationName={animationInfo} image='icon2' name={reviews_arr[slide]} detail={reviews_detail_arr[slide]}/> 
                        <ArrowBtn onClick={changeSlideL} style={{ left:"2%", position:'absolute'}}><FontAwesomeIcon  style={{cursor:'pointer'}} icon={faChevronLeft}/></ArrowBtn>
                        <ArrowBtn onClick={changeSlideR} style={{ right:"2%", position:'absolute'}}><FontAwesomeIcon  style={{cursor:'pointer'}} icon={faChevronRight}/></ArrowBtn>
                    </SmallDisplay>
                </div>
            </Waypoint>
            <div  style={{width:'max-content', margin:'auto', marginBottom:'5rem', marginTop:'4rem', overflow: 'auto'}}>
                <a href='https://www.yelp.com/biz/feodoroff-law-pllc-new-york-2?uid=gmTLB5U4gvFeNZ88TT7AXw&utm_campaign=www_business_share_popup&utm_medium=copy_link&utm_source=(direct)'><SquareButton style={{float:'left', marginRight:'2rem', borderColor:'#d9504b'}}>YELP REVIEWS</SquareButton></a>
                <a href='https://www.google.com/search?q=feodoroff+law+pllc&rlz=1C5CHFA_enUS853US856&oq=feodoroff&aqs=chrome.0.69i59j46i20i175i199i263j69i57j69i60l5.3047j0j4&sourceid=chrome&ie=UTF-8#lrd=0x89c25b7b9c5f5a8b:0xbf4d642cb2c68626,1,,,'><SquareButton style={{float:'left', borderColor:'#009ae5'}}>GOOGLE REVIEWS</SquareButton></a>
            </div>
        </Bg>

        {/* <div css={css`
            @media (max-width: 600px) {
                background-color: #eeeef0;                               
            }
            `} style={{height:'4rem'}} >
        </div> */}
       </>
    )
}

export default ReviewsSec
