import { useStaticQuery, graphql } from "gatsby"
export const useImagesServices = () => {
  const allImages = useStaticQuery(
    graphql`
      query imagesServices {
        icon1: file(relativePath: { eq: "images/services/icon1.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        icon2: file(relativePath: { eq: "images/services/icon2.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        image_ex: file(relativePath: { eq: "images/services/per_diem_ex.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        image_ex2: file(relativePath: { eq: "images/services/per_diem_ex2.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        image_ex3: file(relativePath: { eq: "images/services/per_diem_ex3.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        image_aux1: file(relativePath: { eq: "images/services/judge.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_0: file(relativePath: { eq: "images/services/asylum.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3500) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_1: file(relativePath: { eq: "images/services/asylum.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3500) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_2: file(relativePath: { eq: "images/services/reopen.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 4032) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_3: file(relativePath: { eq: "images/services/defense.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 2625) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_4: file(relativePath: { eq: "images/services/archives.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3264) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_5: file(relativePath: { eq: "images/services/marriage.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_6: file(relativePath: { eq: "images/services/barriers2.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 5472) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_7: file(relativePath: { eq: "images/services/domestic.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 4288) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_8: file(relativePath: { eq: "images/services/law_enforcement.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 5111) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        s_9: file(relativePath: { eq: "images/services/traficking.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 5184) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return allImages
}