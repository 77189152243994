import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
// import { Link } from "gatsby"
import { useImagesServices } from "../hooks/use-images-services"
import { Actionbutton } from './common'
import { ServicesDetail } from './services_list'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { AnchorLink } from "gatsby-plugin-anchor-links";


// import { Waypoint } from 'react-waypoint';


// padding-top: 8rem;

const Bg = styled.div`
   background-color: white;
   width: 100%;
   color: #888;

   h1{
       color: #333;
       text-align: center; 
       margin-top: 8rem;
   }
`
// background-color: #f6f6f6;
const ServiceHeading = styled.button`
   color: #888;
   border: 1px solid #aaa;
   border-right-style: none;
   border-left-style: none;
   min-width: max-content;
   width: 32%;
   margin-right: 2%;
   text-align: center;
   font-size: 1.4rem;
   padding: 1.4rem;
   float: left;
   cursor: pointer;
   outline: 0;
   background-color: white;


   ${p => p.name == p.selected ? css`
        background-color: white;
        color: #0099df;
        border: 2px solid #0099df;
        border-right-style: none;
        border-left-style: none;
    ` : css`
        color: #888;
        background-color: white;
    `}

    @media (max-width: 981px) {
        width: 48%;
        margin-right: 2%;
      }
`

const ServiceContent = styled.div`
   padding: 1.6rem 3rem;
   overflow: auto;

   ${p => p.section ? css`
        display: block;
    ` : css`
        display: none;
    `}
`
const ImgContainer = styled.div`
    width: 29%;
    float: left;
`
const DetailContainer = styled.div`
   padding: 1rem;
   margin-left: 5%;
   width: 63%;
   float: left;
   font-size: 1.4rem;
   h2{
       font-size: 2rem;
       color: #333;
   }
   
   color: #666;

   li{
        color: #666;
   }
   @media (max-width: 630px) {
        p{
            text-align: left;
        }
    }
`

const WebContainer = styled.div`
    margin-bottom: 4rem;

    @media (max-width: 980px) {
        display: none;
      }
`

const TabletContainer = styled.div`
    margin-bottom: 4rem;
    display: none;
    
    @media (max-width: 981px) {
        display: block;
      }
    @media (max-width: 630px) {
        display: none;
    }
`
const MobileContainer = styled.div`
    margin-bottom: 4rem;
    display: none;
    
    @media (max-width: 629px) {
        display: block;
      }
    
`

let Images =  {}

const ServicesDisplay = (props) =>{
    let section1, section2, section3 = false
    var section = ''

    if(props.selected == 0){
        // scrollTo('#is')
    }else{
        if(props.selected <= 3){
            section1 = true
            // try{scrollTo('#is')}catch(err){}
            section = '#is'
        }else if(props.selected <= 6){
            section2 = true
            // scrollTo('#web_first')
            section = '#web_first'
        }else if(props.selected <= 9){
            section3 = true
            // scrollTo('#web_second')
            section = '#web_second'
        }
    }
    if(typeof window !== "undefined"){
        if(window.innerWidth >= 980){
            props.scrollToSection(section)
        }
    }
    
    

    return(
    
        <WebContainer>
            <div id='web_first' style={{overflow:'auto'}}>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='1' selected={props.selected}>Asylum,WR, and CAT</ServiceHeading>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='2' selected={props.selected}>Deportation & Removal Defense (Deportation)</ServiceHeading>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='3' selected={props.selected} style={{marginRight:'0'}}>Appeals</ServiceHeading>
            </div>
            <ServiceContent section={section1}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='web_second' style={{overflow:'auto', marginTop:'2rem'}}>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='4' selected={props.selected}>Motions to Reopen</ServiceHeading>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='5' selected={props.selected}>Adjustment of Status</ServiceHeading>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='6' selected={props.selected} style={{marginRight:'0'}}>Removal of Conditions</ServiceHeading>
            </div>
            <ServiceContent section={section2}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='web_third' style={{overflow:'auto', marginTop:'2rem'}}>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='7' selected={props.selected}>Domestic Violence</ServiceHeading>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='8' selected={props.selected}>Cooperating With Law Enforcement</ServiceHeading>
                <ServiceHeading onClick={(evt)=>{props.selectService(evt)}} name='9' selected={props.selected} style={{marginRight:'0'}}>Victims of Human Trafficking</ServiceHeading>
            </div>
            <ServiceContent section={section3}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>
        </WebContainer>

    )
}

const ServicesDisplayTablet = (props) =>{
    let section1, section2, section3, section4, section5 = false
    var section = ''

    if(props.selected == 0){
        // scrollTo('#is')
    }else{
        if(props.selected <= 2 && props.selected != 0){
            section1 = true
            // try{scrollTo('#is')}catch(err){}
            section = '#is'

        }else if(props.selected <= 4 ){
            section2 = true
            // scrollTo('#tablet_first')
            section = '#tablet_first'

        }else if(props.selected <= 6 ){
            section3 = true
            // scrollTo('#tablet_second')
            section = '#tablet_second'
            
        }else if(props.selected <= 8 ){
            section4 = true
            section = '#tablet_third'
            // scrollTo('#tablet_third')
        }else{
            section5 = true
            // scrollTo('#tablet_fourth')
            section = '#tablet_fourth'
        }
       
        if(typeof window !== "undefined"){

            if(window.innerWidth < 980 && window.innerWidth >= 630){
                props.scrollToSection(section)
            }
        }
    }

    return(
    
        <TabletContainer>
           <div id='tablet_first' style={{overflow:'auto'}}>
                <ServiceHeading onClick={props.selectService} name='1' selected={props.selected}>Asylum,WR, and CAT</ServiceHeading>
                <ServiceHeading style={{marginRight:'0', width:'50%'}} onClick={props.selectService} name='2' selected={props.selected}>Deportation & Removal Defense (Deportation)</ServiceHeading>
            </div>
            <ServiceContent section={section1}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>
           
            <div id='tablet_second' style={{overflow:'auto', marginTop:'2rem'}}>
                <ServiceHeading onClick={props.selectService} name='3' selected={props.selected}>Appeals</ServiceHeading>
                <ServiceHeading style={{marginRight:'0', width:'50%'}} onClick={props.selectService} name='4' selected={props.selected}>Motions to Reopen</ServiceHeading>
            </div>
            <ServiceContent section={section2}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='tablet_third' style={{overflow:'auto', marginTop:'2rem'}}>
                <ServiceHeading onClick={props.selectService} name='5' selected={props.selected}>Adjustment of Status</ServiceHeading>
                <ServiceHeading style={{marginRight:'0', width:'50%'}} onClick={props.selectService} name='6' selected={props.selected}>Removal of Conditions</ServiceHeading>
            </div>
            <ServiceContent section={section3}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='tablet_fourth' style={{overflow:'auto', marginTop:'2rem'}}>
                <ServiceHeading onClick={props.selectService} name='7' selected={props.selected}>Domestic Violence</ServiceHeading>
                <ServiceHeading style={{marginRight:'0', width:'50%'}} onClick={props.selectService} name='8' selected={props.selected}>Cooperating With Law Enforcement</ServiceHeading>
            </div>
            <ServiceContent section={section4}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div style={{overflow:'auto', marginTop:'2rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='9' selected={props.selected}>Victims of Human Trafficking</ServiceHeading>
            </div>
            <ServiceContent section={section5}>
                <ImgContainer>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>
        </TabletContainer>

    )
}

const ServicesDisplayMobile = (props) =>{
    
    let section1, section2, section3, section4, section5, section6, section7, section8, section9 = false
    var section = ''
    
    if(props.selected == 0){
        // scrollTo('#is')
    }else{
        if(props.selected == 1 && props.selected != 0){
            section1 = true
            section = '#is'

        }else if(props.selected == 2 ){
            section2 = true
            section = '#is2'

        }else if(props.selected == 3 ){
            section3 = true
            section = '#mob_first'

        }else if(props.selected == 4 ){
            section4 = true
            section = '#mob_second'

        }else if(props.selected == 5 ){
            section5 = true
            section = '#mob_third'

        }else if(props.selected == 6 ){
            section6 = true
            section = '#mob_fourth'

        }else if(props.selected == 7 ){
            section7 = true
            section = '#mob_fifth'

        }else if(props.selected == 8 ){
            section8 = true
            section = '#mob_sixth'

        }else if(props.selected == 9 ){
            section9 = true
            section = '#mob_seventh'

        }

        if(typeof window !== "undefined"){
            if(window.innerWidth < 630){
                props.scrollToSection(section)
            }
        }
    }

    return(
    
        <MobileContainer>
            <div id='mob_first' style={{overflow:'auto'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='1' selected={props.selected}>Asylum, WR, and CAT </ServiceHeading>
            </div>
            <ServiceContent section={section1}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_second' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='2' selected={props.selected}>Deportation & Removal Defense (Deportation)</ServiceHeading>
            </div>
            <ServiceContent section={section2}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_third' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='3' selected={props.selected}>Appeals</ServiceHeading>
            </div>
            <ServiceContent section={section3}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_fourth' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='4' selected={props.selected}>Motions to Reopen</ServiceHeading>
            </div>
            <ServiceContent section={section4}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_fifth' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='5' selected={props.selected}>Adjustment of Status</ServiceHeading>
            </div>
            <ServiceContent section={section5}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_sixth' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='6' selected={props.selected}>Removal of Conditions</ServiceHeading>
            </div>
            <ServiceContent section={section6}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_seventh' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='7' selected={props.selected}>Domestic Violence</ServiceHeading>
            </div>
            <ServiceContent section={section7}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div id='mob_eight' style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='8' selected={props.selected}>Cooperating With Law Enforcement</ServiceHeading>
            </div>
            <ServiceContent section={section8}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>

            <div style={{overflow:'auto', marginTop:'1rem'}}>
                <ServiceHeading style={{width:'90%', marginLeft:'5%'}} onClick={props.selectService} name='9' selected={props.selected}>Victims of Human Trafficking</ServiceHeading>
            </div>
            <ServiceContent section={section9}>
                <ImgContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <Img style={{width: '100%', heigth: '100%'}} id='service_img' fluid={Images[`s_${props.selected}`].childImageSharp.fluid}/>   
                </ImgContainer>
                <DetailContainer style={{width:'90%', marginLeft:'5%', float: 'none'}}>
                    <ServicesDetail selected={props.selected}/>
                </DetailContainer>
            </ServiceContent>
    
        </MobileContainer>

    )
}

const ServicesSec = (props) =>{
    // const [service, setService] = useState(props.item);
    // pickService
    Images = useImagesServices()

    // console.log(ServicesDetail)
    // const selectS = (evt) =>{
    //     setService(evt.target.name)
    //     // console.log(evt.target.name)
    // }

    return(
        <>
            <Bg id='is'>
                <h1 id='is2'>Immigration Services</h1>
                <p style={{maxWidth: '90%', marginLeft:'5%', textAlign:'center'}}>We offer quality immigration representation in the following areas</p>
                <ServicesDisplay scrollToSection={props.scrollToSection} selectService={props.pickService} selected={props.item} images={Images}/>
                <ServicesDisplayTablet scrollToSection={props.scrollToSection} selectService={props.pickService} selected={props.item} images={Images}/>
                <ServicesDisplayMobile scrollToSection={props.scrollToSection} selectService={props.pickService} selected={props.item} images={Images}/>
                <AnchorLink  css={css`
                    text-decoration: none;
                    
                    `} to="/#contact"><Actionbutton style={{margin:'auto', marginBottom: '4rem'}}>Contact Us</Actionbutton></AnchorLink>
            </Bg>   
       </>
    )
}

export default ServicesSec
